import styled from 'styled-components';
import { media } from '@oneflare/flarekit/lib/libs';
import { Close, No, Success } from '@oneflare/flarekit';

export const NotificationWrapperStyled = styled.div`
  color: ${({ theme }) => theme.color.white};
  padding: 16px 20px;

  ${media.lgUp`
    padding: 16px 40px;
  `}

  ${({ kind, theme }) => {
    switch (kind) {
      case 'success':
        return `background-color: ${theme.color.successDark};`;

      case 'warning':
      case 'danger':
      case 'error':
        return `background-color: ${theme.color.dangerDark};`;

      case 'alert':
      case 'dark':
        return `background-color: ${theme.color.charcoal};`;

      default:
        return `background-color: ${theme.color.infoDark};`;
    }
  }}
`;

export const CloseIconStyled = styled(Close).attrs(({ theme }) => ({
  fill: theme.color.white
}))`
  height: initial;
  width: initial;
  cursor: pointer;
`;

export const IconStyled = styled.div`
  display: none;

  ${media.lgUp`
    height: 24px;
    width: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 0 16px 0 0;
  `}
`;

export const NotificationStyled = styled.section`
  overflow: hidden;
  transition: max-height 0.2s linear;
  color: ${({ theme }) => theme.color.white};
  margin: 0 0 1px;

  a {
    color: ${({ theme }) => theme.color.white};
    text-decoration: underline;
  }
`;

export const NotificationBodyStyled = styled.div`
  align-items: center;
  display: flex;
`;

export const NotificationContentStyled = styled.div`
  flex: 1;
  font-size: 16px;
  line-height: 1.5;
`;

export const StopIconStyled = styled(No).attrs(({ theme }) => ({
  fill: theme.color.white
}))`
  height: 24px;
  width: 24px;
`;

export const SuccessIconStyled = styled(Success).attrs(({ theme }) => ({
  fill: theme.color.white,
  kind: 'solid',
  tickFillColor: 'transparent'
}))`
  height: 24px;
  width: 24px;
`;

export const CloseButtonStyled = styled.button`
  background: none;
  box-sizing: border-box;
  border: 0;
  flex-basis: 20px;
  height: 20px;
  outline: none;
  padding: 2px;
`;
