import React, { PureComponent, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Media from 'react-media';
import { Header } from '@oneflare/flarekit';
import { withTheme } from 'styled-components';
import Notification from '../Notification';
import { themeType } from '../../../../shared/chat/types';

const ClientHeader = (props) => {
  const [ssrDone, setSSrDone] = useState(false);

  useEffect(() => {
    setSSrDone(true);
  }, []);

  return ssrDone && <Header withCobranding {...props} />;
};
class OneflareHeader extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      notifications: props.notifications
    };
  }

  static propTypes = {
    currentEnv: PropTypes.string,
    notifications: PropTypes.arrayOf(
      PropTypes.shape({
        closable: PropTypes.bool,
        icon: PropTypes.bool,
        id: PropTypes.string,
        message: PropTypes.string,
        type: PropTypes.string
      })
    ),
    showOpenInApp: PropTypes.bool,
    siteurl: PropTypes.string,
    theme: themeType.isRequired,
    user: PropTypes.shape({
      avatarUrl: PropTypes.string,
      creditBalance: PropTypes.shape({
        current: PropTypes.number,
        committed: PropTypes.number
      }),
      email: PropTypes.string,
      id: PropTypes.number,
      instantAccessEnabled: PropTypes.bool,
      isBusiness: PropTypes.bool,
      customerModeOverride: PropTypes.bool,
      jobMessagesCount: PropTypes.node,
      name: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      quoteMessagesCount: PropTypes.node,
      unseenSupportConversationCount: PropTypes.number
    })
  };

  static defaultProps = {
    notifications: [],
    showOpenInApp: false,
    siteurl: '',
    currentEnv: process.env.NODE_ENV,
    user: null
  };

  componentDidMount() {
    window.addEventListener('message', this.handleMessage);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleMessage, true);
  }

  handleMessage = (event) => {
    const { data } = event;
    if (data.ofMessageType === 'FAILED_PAYMENT_RESOLVED') {
      const { notifications } = this.state;
      const newNotifications = notifications.filter((notification) => {
        return notification.id !== 'FAILED_PAYMENT';
      });
      this.setState({
        notifications: newNotifications
      });
    }
  };

  handleClose = (index) => {
    const { notifications } = this.state;
    const tempArray = [...notifications];
    tempArray.splice(index, 1);
    this.setState({ notifications: tempArray });
  };

  getFilteredNotifications = () => {
    const { notifications } = this.state
    return this.props.user && this.props.user.instantAccessEnabled
      ? notifications.filter((notification) => notification.type !== 'alert')
      : notifications;
  }

  render() {
    // eslint-disable-next-line react/destructuring-assignment
    const smBreakPoint = this.props.theme.breakpoint.sm.min.split('px')[0];
    const IPHONE_X_WIDTH = smBreakPoint - 201;
    const { showOpenInApp, siteurl, user } = this.props;
    const newUser = user && {
      ...user,
      ...(user?.lastName ? { lastName: user?.lastName } : { lastName: '' })
    };
    const newProps = {
      ...this.props,
      ...(newUser && { user: newUser })
    };
    return (
      <div>
        {this.getFilteredNotifications().map((notification, index) => (
          <Notification
            handleClose={this.handleClose}
            id={index}
            key={notification.message}
            kind={notification.type}
            {...notification}
          >
            <span
              dangerouslySetInnerHTML={{ __html: notification.message }}
            />
          </Notification>
          ))}
        {showOpenInApp ? (
          <Media query={`(min-width: ${IPHONE_X_WIDTH}px`}>
            {(matches) => {
              const headerProps = {
                ...newProps,
                mobileCta: {
                  label: matches ? 'Open in app' : 'Open app',
                  link: `${siteurl}/oneflare-app?utm_campaign=header`
                }
              };
              return <ClientHeader {...headerProps} />;
            }}
          </Media>
        ) : (
          <Header withCobranding {...newProps} />
        )}
      </div>
    );
  }
}

export default withTheme(OneflareHeader);
