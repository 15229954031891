import PropTypes from 'prop-types';

export const idType = PropTypes.oneOfType([
  PropTypes.number,
  PropTypes.string
]);

export const messageType = PropTypes.shape({
  id: idType.isRequired,
  conversationId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  fromId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  quoteId: PropTypes.number,
  tempId: PropTypes.string,
  messageType: PropTypes.string,
  header: PropTypes.string,
  isSystemMessage: PropTypes.bool.isRequired,
  content: PropTypes.string,
  rawContent: PropTypes.string,
  seen: PropTypes.bool.isRequired,
  createdAt: PropTypes.string.isRequired,
  data: PropTypes.shape({
    categories: PropTypes.arrayOf(PropTypes.shape({
      iconUrl: PropTypes.string,
      id: idType,
      name: PropTypes.string
    }))
  })
});

export const quoteType = PropTypes.shape({
  id: idType,
  currentState: PropTypes.string,
  friendlyState: PropTypes.string,
  friendlyQuoteStatus: PropTypes.string,
  price: PropTypes.string,
  category: PropTypes.string,
  hasFeedback: PropTypes.bool
});

export const counterpartType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  avatarColour: PropTypes.string,
  phone: PropTypes.string
});

export const verificationType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  imageUrl: PropTypes.shape({
    inlineSvg: PropTypes.string,
    svg: PropTypes.string,
    pvg: PropTypes.string
  }),
  verifiedValue: PropTypes.string.isRequired,
  titleDescription: PropTypes.string.isRequired,
  state: PropTypes.string.isRequired,
  stateDescription: PropTypes.string.isRequired
});

export const customerType = PropTypes.shape({
  name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  phoneFormatted: PropTypes.string.isRequired,
  mobileVerified: PropTypes.bool.isRequired,
  emailVerified: PropTypes.bool.isRequired,
  abTestedWithHidePhoneUntilQuoteSeen: PropTypes.bool.isRequired,
  verifications: PropTypes.arrayOf(verificationType)
});

export const conversationType = PropTypes.shape({
  id: idType.isRequired,
  numberOfUnread: PropTypes.number.isRequired,
  counterpart: counterpartType.isRequired,
  lastMessage: messageType,
  latestMessage: idType.isRequired,
  quote: quoteType.isRequired,
  isTyping: PropTypes.bool
});

export const supportConversationType = PropTypes.shape({
  id: idType.isRequired,
  numberOfUnread: PropTypes.number.isRequired,
  counterpart: counterpartType.isRequired,
  lastMessage: messageType,
  latestMessage: idType.isRequired,
  isTyping: PropTypes.bool
});

export const conversationRouteMatchType = PropTypes.shape({
  params: PropTypes.shape({
    conversationId: idType
  })
});

export const conversationRouteLocationType = PropTypes.shape({
  params: PropTypes.shape({
    pathname: PropTypes.string.isRequired
  })
});

export const conversationHistoryLocationType = PropTypes.shape({
  push: PropTypes.func
});

export const themeType = PropTypes.shape({
  breakpoint: PropTypes.shape({
    xs: PropTypes.shape({
      min: PropTypes.string,
      max: PropTypes.string
    }),
    sm: PropTypes.shape({
      min: PropTypes.string,
      max: PropTypes.string
    }),
    md: PropTypes.shape({
      min: PropTypes.string,
      max: PropTypes.string
    }),
    lg: PropTypes.shape({
      min: PropTypes.string,
      max: PropTypes.string
    }),
    xl: PropTypes.shape({
      min: PropTypes.string
    })
  })
});

export const conversationIdsType = PropTypes.arrayOf(PropTypes.number);

export const renderConversationInRouteType = PropTypes.shape({
  history: conversationHistoryLocationType.isRequired,
  location: conversationRouteLocationType.isRequired,
  match: conversationRouteMatchType.isRequired
});

export const userProfileType = PropTypes.shape({
  id: idType,
  name: PropTypes.string,
  avatar: PropTypes.string,
  email: PropTypes.string,
  emailVerified: PropTypes.bool,
  mobile: PropTypes.string,
  mobileVerified: PropTypes.bool,
  userAccountLink: PropTypes.string,
  isBusiness: PropTypes.bool,
  totalJobsPosted: PropTypes.number,
  openJobs: PropTypes.arrayOf(PropTypes.shape({
    category: PropTypes.string.isRequired,
    link: PropTypes.string.isRequired
  })),
  impersonateWeb: PropTypes.string,
  authenticationToken: PropTypes.string,
  businessName: PropTypes.string,
  plan: PropTypes.string,
  creditBalance: PropTypes.string,
  accountManager: PropTypes.string,
  error: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired
});

export const photosType = PropTypes.shape({
  attachments: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    thumb: PropTypes.string,
    original: PropTypes.string
  })),
  error: PropTypes.bool,
  loading: PropTypes.bool
});
