import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import {
  CloseButtonStyled,
  CloseIconStyled,
  NotificationBodyStyled,
  NotificationContentStyled,
  NotificationStyled,
  NotificationWrapperStyled,
  IconStyled,
  StopIconStyled,
  SuccessIconStyled
} from './styled/Notification';

const icons = {
  stop: () => <StopIconStyled kind="hollow" />,
  success: () => <SuccessIconStyled kind="hollow" />
};

class Notification extends PureComponent {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.node),
      PropTypes.node
    ]).isRequired,
    className: PropTypes.string,
    closable: PropTypes.bool,
    handleClose: PropTypes.func,
    icon: PropTypes.oneOf(['stop', 'success']),
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    type: PropTypes.oneOf(['success', 'notice', 'danger', 'error', 'warning', 'info', 'dark', 'alert'])
  };

  static defaultProps = {
    type: 'info',
    closable: true,
    icon: null,
    handleClose: () => {},
    className: ''
  };

  handleClose = () => {
    const { handleClose, id } = this.props;
    handleClose(id);
  };

  render() {

    const {
      children,
      handleClose,
      type,
      closable,
      icon,
      className
    } = this.props;

    const Icon = icons[icon];

    return (
      <NotificationStyled data-testid="notification-banner">
        <NotificationWrapperStyled kind={type} className={className}>
          <NotificationBodyStyled>
            {Icon
              && (
              <IconStyled>
                <Icon />
              </IconStyled>
            )}
            <NotificationContentStyled>
              {children}
            </NotificationContentStyled>
            {closable && (
              <CloseButtonStyled onClick={handleClose}>
                <CloseIconStyled kind="hollow" />
              </CloseButtonStyled>
            )}
          </NotificationBodyStyled>
        </NotificationWrapperStyled>
      </NotificationStyled>
    );
  }
}

export default Notification;
